import React from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { NavigationContainer } from "@react-navigation/native";
import Home from "../pages/Home";
import DrawerC from "../containers/Drawer";
import { useStoreState } from "easy-peasy";

const Drawer = createDrawerNavigator();

export default function AppDrawerNavigator() {
  const { auth, employee } = useStoreState((state) => state);
  return (
    <NavigationContainer>
      <Drawer.Navigator
        screenOptions={{
          headerShown:
            auth.user.email && employee.loggedInEmployee.EmployeeID
              ? true
              : false,
          headerRight: DrawerC.Header,
        }}
        drawerContent={DrawerC.Content}
      >
        <Drawer.Screen
          name="Home"
          component={Home}
          options={{
            title: "",
          }}
        />
      </Drawer.Navigator>
    </NavigationContainer>
  );
}
