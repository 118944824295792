import { Modal as RNModal } from "react-native";
import styled from "styled-components/native";
import { vwToDp } from "../../utilities/responsive";

const Container = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #00000080;
`;

Container.Content = styled.View`
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  align-items: center;
  max-width: ${vwToDp(85)}px;
  width: 100%;
`;

function Modal({ children, ...props }) {
  return (
    <RNModal animationType="slide" transparent={true} {...props}>
      <Container>
        <Container.Content>{children}</Container.Content>
      </Container>
    </RNModal>
  );
}

export default Modal;
