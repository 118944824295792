import { useStoreActions, useStoreState } from "easy-peasy";
import Input from "../../presentational/Input";
import Modal from "./Modal";
import Center from "../../presentational/Center";
import { Text } from "react-native-elements";
import styled from "styled-components/native";
import Button from "../Button";
import Gap from "../../presentational/Gap";
import { remToDp } from "../../../utilities/responsive";

const Label = styled(Text)`
  font-size: ${remToDp(1.5)}px;
`;

function CheckInToolModal(props) {
  const { showCheckInToolModal, toolIdInput } = useStoreState(
    (state) => state.tool
  );

  const { showCheckInToolModalAction, setToolIdInputAction, checkInToolThunk } =
    useStoreActions((actions) => actions);

  return (
    <Modal
      visible={showCheckInToolModal}
      onRequestClose={() => showCheckInToolModalAction(false)}
      {...props}
    >
      <Input
        value={toolIdInput}
        onChangeText={(toolId) => setToolIdInputAction(toolId)}
        label={
          <Center>
            <Label>Tool ID To Check In</Label>
          </Center>
        }
        placeholder="Enter tool ID to check in"
        onSubmitEditing={() => checkInToolThunk()}
      />

      <Gap />

      <Button.CloseCheckInToolPrompt />
    </Modal>
  );
}

export default CheckInToolModal;
