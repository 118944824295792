import { action } from "easy-peasy";

const actions = {
  setBadgeIdAction: action((state, badgeId) => {
    state.employee.badgeId = badgeId;
  }),
  setLoggedInEmployeeAction: action((state, employee) => {
    state.employee.loggedInEmployee = employee;
  }),
  setScannedEmployeeAction: action((state, employee) => {
    state.employee.scannedEmployee = employee;
  }),
};

export default actions;
