import { StoreProvider } from "easy-peasy";
import store from "./src/store";
import AppDrawerNavigator from "./src/components/navigation/AppDrawerNavigator";
import { axiosInterceptor } from "./src/utilities/api";
import { authAxios, authLoginAxios } from "./src/api/auth";
import { employeeAxios } from "./src/api/employee";
import { toolAxios } from "./src/api/tool";
import { taskAxios } from "./src/api/task";

export default function App() {
  axiosInterceptor(authAxios);
  axiosInterceptor(authLoginAxios);
  axiosInterceptor(employeeAxios);
  axiosInterceptor(toolAxios);
  axiosInterceptor(taskAxios);

  return (
    <StoreProvider store={store}>
      <AppDrawerNavigator />
    </StoreProvider>
  );
}
