import { View } from "react-native";
import Col from "../../presentational/Col";
import Row from "../../presentational/Row";
import Text from "../../presentational/Text";
import styled from "rn-css";
import { useStoreState } from "easy-peasy";
import DrawerBrand from "./DrawerBrand";

const Container = styled(Row)`
  width: 100%;
  justify-content: space-between;
`;
const SText = styled(Text)`
  text-align: right;

  @media (max-width: 576px) {
    display: none;
  }
`;

function DrawerHeader() {
  const {
    auth: {
      acumaticaConfig: { logo },
    },
    employee: {
      loggedInEmployee: { FirstName, LastName, KioskRole },
    },
  } = useStoreState((state) => state);

  return (
    <Container>
      <Col xs={0} sm={3} />
      <Col xs={0} sm={4}>
        <DrawerBrand height="100%" width="100%" />
      </Col>
      <Col xs={0} sm={5}>
        <View>
          <SText>
            {FirstName} {LastName}
          </SText>
          <SText>Role: {KioskRole}</SText>
        </View>
      </Col>
    </Container>
  );
}

export default DrawerHeader;
