import { createStore } from "easy-peasy";
import statusStore from "./status";
import authStore from "./auth";
import employeeStore from "./employee";
import toolStore from "./tool";
import taskStore from "./task";

const store = createStore({
  ...statusStore,
  ...authStore,
  ...employeeStore,
  ...toolStore,
  ...taskStore,
});

export default store;
