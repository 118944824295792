import React from "react";
import styled from "styled-components/native";
import Text from "./Text";
import { Input as RNInput } from "@rneui/themed";

const SInput = styled(RNInput)`
  background-color: ${(props) =>
    props.editable ? "rgba(0, 0, 0, 0)" : "lightgray"};
  padding: 0 10px;
  width: 100%;
  color: #00205b;
`;

const Label = styled(Text)`
  color: #00205b;
`;

const RequiredLabel = styled(Label)`
  color: #ab2328;
`;

function Input({
  label,
  required = false,
  dropdown = false,
  dropdownChoices,
  rowTextForSelection,
  selectedItem,
  onSelect,
  ...props
}) {
  return (
    <>
      {label && (
        <Label>
          {label} {required && <RequiredLabel>*</RequiredLabel>}
        </Label>
      )}
      <SInput placeholderTextColor="#76787A" editable={true} {...props} />
    </>
  );
}

export default Input;
