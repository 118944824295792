import Page from "./Page";
import styled from "styled-components/native";
import Input from "../../presentational/Input";
import Center from "../../presentational/Center";
import Card from "../../presentational/Card";
import Button from "../Button";
import Gap from "../../presentational/Gap";
import {
  MaterialCommunityIcons,
  FontAwesome5,
} from "react-native-vector-icons";
import Text from "../../presentational/Text";
import Icon from "../../presentational/Icon";
import Image from "../../presentational/Image";
import React from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import { remToDp, vhToDp, vwToDp } from "../../../utilities/responsive";

const SCard = styled(Card)`
  max-width: ${vwToDp(70)}px;
`;

const Header = styled(Text)`
  font-size: ${remToDp(1.75)}px;
  text-align: center;
`;

function LoginPage() {
  const loginInput = useStoreState((state) => state.auth.loginInput);

  const { setLoginInputAction, loginThunk } = useStoreActions(
    (actions) => actions
  );

  return (
    <Page>
      <Center.Screen>
        <SCard>
          <Center>
            <Image
              source={require("../../../../assets/favicon.png")}
              height={vhToDp(12)}
              width="20%"
            />
          </Center>

          <Gap />

          <Header>Login</Header>

          <Gap />

          <Input
            onChangeText={(email) => setLoginInputAction({ email })}
            value={loginInput.email}
            placeholder="Type email"
            inputMode="email"
            leftIcon={<Icon Component={MaterialCommunityIcons} name="email" />}
            onSubmitEditing={() => loginThunk()}
          />

          <Gap />

          <Input
            onChangeText={(password) => setLoginInputAction({ password })}
            value={loginInput.password}
            placeholder="Type password"
            secureTextEntry
            leftIcon={<Icon Component={FontAwesome5} name="lock" />}
            onSubmitEditing={() => loginThunk()}
          />

          <Gap />

          <Button.Login />
        </SCard>
      </Center.Screen>
    </Page>
  );
}

export default LoginPage;
