import Page from "./Page";
import styled from "styled-components/native";
import Input from "../../presentational/Input";
import Center from "../../presentational/Center";
import Gap from "../../presentational/Gap";
import Text from "../../presentational/Text";
import React, { useEffect } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import Icon from "../../presentational/Icon";
import { FontAwesome } from "@expo/vector-icons";
import { pxToDp, remToDp, vwToDp } from "../../../utilities/responsive";
import Button from "../Button";
import Drawer from "../Drawer";

const Header = styled(Text)`
  font-size: ${remToDp(2.5)}px;
  text-align: center;
  color: #333;
`;

const FieldLabel = styled(Text)`
  font-size: ${remToDp(1.75)}px;
  font-weight: 500;
  margin-bottom: ${remToDp(0.5)}px;
  color: #6c757d;
`;

const BadgeInput = styled(Input)`
  text-align: center;
`;

const LogoContainer = styled.View`
  background-color: #e9ecef;
  border-radius: ${remToDp(0.3)}px;
  margin-bottom: ${remToDp(2)}px;
  padding: ${remToDp(4)}px ${remToDp(2)}px;
  max-width: 60%;
  width: 100%;
`;

const CreditCardIcon = styled(Icon)`
  color: #6c757d;
`;

const Navbar = styled.View`
  padding: ${remToDp(1.75)}px ${remToDp(2)}px;
  border-bottom-width: 1px;
  border-color: #2c6892;
`;

function BadgePage() {
  const {
    employee: { badgeId },
    auth: { user, acumaticaConfig },
  } = useStoreState((state) => state);

  const { setBadgeIdAction, loginAcmThunk, loginEmployeeThunk } =
    useStoreActions((actions) => actions);

  useEffect(() => {
    !acumaticaConfig.logo && user.email && loginAcmThunk();
  }, [acumaticaConfig.logo, user.email]);

  return (
    <Page>
      <Navbar>
        <Drawer.Brand height={pxToDp(100)} width="15%" />
      </Navbar>

      <Center>
        <LogoContainer>
          <Drawer.Brand height={pxToDp(175)} width="100%" />
        </LogoContainer>

        <Header>ProMRO Tool Counter Sign In</Header>

        <Gap />
        <Gap />

        <CreditCardIcon
          Component={FontAwesome}
          name="credit-card-alt"
          size={remToDp(6)}
        />

        <Gap />
        <Gap />

        <FieldLabel>Scan badge or enter ID to Sign-in</FieldLabel>

        <Gap />
        <Gap />

        <BadgeInput
          onChangeText={(badgeIdInput) => setBadgeIdAction(badgeIdInput)}
          value={badgeId}
          placeholder="Type your badge ID"
          secureTextEntry
          onSubmitEditing={() => loginEmployeeThunk()}
          containerStyle={{ maxWidth: vwToDp(30) }}
        />

        <Gap />

        <Button.SelectEnvironment />
      </Center>
    </Page>
  );
}

export default BadgePage;
