import { Image as RNImage } from "@rneui/themed";

function Image({ name, height, width, ...props }) {
  return (
    <RNImage
      containerStyle={{ height, width }}
      resizeMode="contain"
      {...props}
    />
  );
}

export default Image;
