import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import styled from "styled-components/native";

const SButton = styled(Button)`
  margin: 0 10px;
`;

function CloseCheckOutToolPromptButton({ children = "Close", ...props }) {
  const { showCheckOutToolModalAction } = useStoreActions((actions) => actions);
  return (
    <SButton onPress={() => showCheckOutToolModalAction(false)} {...props}>
      {children}
    </SButton>
  );
}

export default CloseCheckOutToolPromptButton;
