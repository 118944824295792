import { isWithinDays } from "./date";

function checkToolCheckoutLimit(helpers) {
  const { auth, tool, employee } = helpers.getState();

  const toolLimit = auth.acumaticaConfig.kioskConfig.cfToolLimit;
  const scannedEmployee = employee.scannedEmployee;
  const numToolsCheckedOut = Math.max(
    tool.checkedOutToolList.length,
    scannedEmployee.numToolsCheckedOut
  );

  if (numToolsCheckedOut >= toolLimit) {
    throw new Error(
      `${scannedEmployee.FirstName} ${scannedEmployee.LastName} cannot check out more than ${toolLimit} tools. Tools checked out already: ${numToolsCheckedOut}.`
    );
  }
}

function checkToolCalibrationCurrent(tool) {
  if (!tool.CalibrationCurrent.value) {
    throw new Error(
      `Cannot check out tool ${tool.ToolID.value} (serial number ${tool.SerialNumber.value}) because calibration is not current.`
    );
  }
}

function checkToolCalibrationExpiration(
  { checkedOutToolList, successMessage },
  tool,
  helpers,
  actions
) {
  const checkedOutTool = checkedOutToolList.find(
    (checkedOutTool) => checkedOutTool.toolId === tool.ToolID.value
  );
  const calibrationDueDate = tool.CalibrationDue.value;

  if (
    calibrationDueDate &&
    isWithinDays(
      checkedOutTool.checkoutDate,
      calibrationDueDate,
      helpers.getState().auth.acumaticaConfig.kioskConfig
        .cfToolCalibrationGraceDays
    )
  ) {
    actions.showToolCalibrationExpirationWarningModalAction(true);
    actions.setAlertThunk({
      type: "warning",
      message: `Tool ${tool.ToolID.value} (Serial Number ${tool.SerialNumber.value}) checked out with warning`,
    });
  } else {
    actions.setAlertThunk({
      type: "success",
      message: successMessage,
    });
  }
}

function checkIfToolExpired(tool) {
  const nowDate = new Date();
  const calibrationDueDate = new Date(tool.CalibrationDue.value);

  if (nowDate.getTime() > calibrationDueDate.getTime()) {
    throw new Error(
      `Tool ${tool.ToolID.value} (Serial Number ${tool.SerialNumber.value}) is expired.`
    );
  }
}

export {
  checkToolCheckoutLimit,
  checkToolCalibrationCurrent,
  checkToolCalibrationExpiration,
  checkIfToolExpired,
};
