import { useStoreState } from "easy-peasy";
import { ActivityIndicator, ScrollView } from "react-native";
import Center from "../../presentational/Center";
import { StatusBar } from "expo-status-bar";
import Alerts from "../Alerts";

function Page({ children, ...props }) {
  const { loading } = useStoreState((state) => state.status);
  return (
    <ScrollView {...props}>
      <StatusBar style="auto" />
      <Alerts />
      {loading ? (
        <Center.Screen>
          <ActivityIndicator size="large" color="#AB2328" />
        </Center.Screen>
      ) : (
        children
      )}
    </ScrollView>
  );
}

export default Page;
