function isWithinDays(dateA, dateB, daysLimit) {
  // Convert the dates to milliseconds
  const timeA = new Date(dateA).getTime();
  const timeB = new Date(dateB).getTime();

  // Calculate the time difference in milliseconds
  const timeDiff = Math.abs(timeA - timeB);

  // Convert milliseconds to days
  const daysDiff = timeDiff / (1000 * 60 * 60 * 24);

  // Check if the difference is less than or equal to 5
  return daysDiff <= daysLimit;
}

export { isWithinDays };
