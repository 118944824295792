import { axios } from ".";

const taskAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/task`,
});

function getTaskAPICall(taskId) {
  return taskAxios({ method: "get", url: `/${taskId}` });
}

export { taskAxios, getTaskAPICall };
