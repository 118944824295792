const defaultState = {
  taskIdInput: "",
  scannedTask: {},
};

const state = {
  task: defaultState,
};

export { state as default, defaultState };
