import { useStoreActions, useStoreState } from "easy-peasy";
import Modal from "./Modal";
import Gap from "../../presentational/Gap";
import List from "../List";
import Button from "../Button";
import SearchBar from "../SearchBar";
import Row from "../../presentational/Row";

function CheckOutToolModal(props) {
  const { showCheckOutToolModal, toolToCheckOut } = useStoreState(
    (state) => state.tool
  );

  const { showCheckOutToolModalAction } = useStoreActions((actions) => actions);

  return (
    <Modal
      visible={showCheckOutToolModal}
      onRequestClose={() => showCheckOutToolModalAction(false)}
      {...props}
    >
      <SearchBar.Tool />

      <Gap />

      <List.Tool />

      <Gap />

      <Row>
        {toolToCheckOut.id && <Button.CheckOutTool />}

        <Button.CloseCheckOutToolPrompt />
      </Row>
    </Modal>
  );
}

export default CheckOutToolModal;
