import { thunk } from "easy-peasy";
import { getEmployeeAPICall, loginEmployeeAPICall } from "../../api/employee";

const thunks = {
  loginEmployeeThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginEmployeeAPICall(
        helpers.getState().employee.badgeId
      );
      actions.setLoggedInEmployeeAction(response.data.employee);
      actions.setValidToolsAction(response.data.tools);
      actions.setBadgeIdAction("");
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  logoutEmployeeThunk: thunk(async (actions, navigation) => {
    actions.clearEmployeeThunk();
    actions.setLoggedInEmployeeAction({});
    navigation.closeDrawer();
  }),
  scanEmployeeThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await getEmployeeAPICall(
        helpers.getState().employee.badgeId
      );
      actions.setTaskIdInputAction("");
      actions.setScannedTaskAction({});
      actions.setToolIdInputAction("");
      actions.setCheckedOutToolListAction([]);
      actions.setScannedEmployeeAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  clearEmployeeThunk: thunk(async (actions, payload, helpers) => {
    actions.setBadgeIdAction("");
    actions.setScannedEmployeeAction({});
    actions.setTaskIdInputAction("");
    actions.setScannedTaskAction({});
    actions.setToolIdInputAction("");
    actions.setCheckedOutToolListAction([]);
  }),
};

export default thunks;
