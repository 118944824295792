import { thunk } from "easy-peasy";
import {
  getAuthUserAPICall,
  loginAPICall,
  loginAcmAPICall,
} from "../../api/auth";
import {
  getAuthToken,
  removeAuthToken,
  setAuthToken,
} from "../../utilities/api";

const thunks = {
  loginThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginAPICall(helpers.getState().auth.loginInput);
      const { token, ...user } = response.data;
      await setAuthToken(token);
      actions.setUserAction(user);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  loginAcmThunk: thunk(async (actions, input) => {
    actions.setLoadingAction(true);

    try {
      const response = await loginAcmAPICall();
      actions.setAcumaticaConfigAction(response.data);
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  selectEnvironmentThunk: thunk(async (actions, payload) => {
    await removeAuthToken();
    actions.resetUserAction();
    actions.resetLoginInputAction();
  }),
  getAuthUserThunk: thunk(async (actions, payload) => {
    actions.setLoadingAction(true);

    try {
      if (await getAuthToken()) {
        const response = await getAuthUserAPICall();
        actions.setUserAction(response.data);
      }
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
};

export default thunks;
