import styled from "rn-css";
import Button from "../Button";

const Container = styled.View`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;
Container.Inner = styled.View`
  margin-top: auto;
`;

function DrawerContent({ navigation, ...props }) {
  return (
    <Container {...props}>
      <Container.Inner>
        <Button.Logout navigation={navigation} />
      </Container.Inner>
    </Container>
  );
}

export default DrawerContent;
