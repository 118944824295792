import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import styled from "styled-components/native";

const SButton = styled(Button)`
  margin: 0 10px;
`;

function CheckOutToolButton({ children = "Check Out Tool", ...props }) {
  const { slowCheckOutToolThunk } = useStoreActions((actions) => actions);
  return (
    <SButton onPress={() => slowCheckOutToolThunk()} {...props}>
      {children}
    </SButton>
  );
}

export default CheckOutToolButton;
