import Button from "../../presentational/Button";
import CheckOutToolButton from "./CheckOutToolButton";
import ClearInputButton from "./ClearInputButton";
import CloseCheckInToolPromptButton from "./CloseCheckInToolPromptButton";
import CloseCheckOutToolPromptButton from "./CloseCheckOutToolPromptButton";
import CloseToolCalibrationExpirationWarningModalButton from "./CloseToolCalibrationExpirationWarningModalButton";
import LoginButton from "./LoginButton";
import LogoutButton from "./LogoutButton";
import SelectEnvironmentButton from "./SelectEnvironmentButton";
import ShowCheckInToolPromptButton from "./ShowCheckInToolPromptButton";
import ShowCheckOutToolPromptButton from "./ShowCheckOutToolPromptButton";

Button.Login = LoginButton;
Button.ShowCheckInToolPrompt = ShowCheckInToolPromptButton;
Button.CloseCheckInToolPrompt = CloseCheckInToolPromptButton;
Button.CheckOutTool = CheckOutToolButton;
Button.ShowCheckOutToolPrompt = ShowCheckOutToolPromptButton;
Button.CloseCheckOutToolPrompt = CloseCheckOutToolPromptButton;
Button.ClearInput = ClearInputButton;
Button.CloseToolCalibrationExpirationWarningModal =
  CloseToolCalibrationExpirationWarningModalButton;
Button.Logout = LogoutButton;
Button.SelectEnvironment = SelectEnvironmentButton;
export default Button;
