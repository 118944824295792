import { axios } from ".";

const authAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/auth`,
});

const authLoginAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/auth/login`,
});

function loginAPICall(data) {
  return authLoginAxios({ method: "post", data });
}

function loginAcmAPICall() {
  return authLoginAxios({ method: "post", url: "/acumatica" });
}

function refreshTokenAPICall() {
  return authAxios({ method: "post", url: "/token/refresh" });
}

function getAuthUserAPICall() {
  return authAxios({ method: "get", url: "/user" });
}

export {
  authAxios,
  authLoginAxios,
  loginAPICall,
  loginAcmAPICall,
  refreshTokenAPICall,
  getAuthUserAPICall,
};
