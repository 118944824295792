import { axios } from ".";

const employeeAxios = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}/employee`,
});

function loginEmployeeAPICall(badgeId) {
  return employeeAxios({ method: "post", url: "/login", data: { badgeId } });
}

function getEmployeeAPICall(badgeId) {
  return employeeAxios({ method: "get", url: `/${badgeId}` });
}

export { employeeAxios, loginEmployeeAPICall, getEmployeeAPICall };
