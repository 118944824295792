import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function ShowCheckInToolPromptButton({ children = "Check In Tool", ...props }) {
  const { showCheckInToolModalAction } = useStoreActions((actions) => actions);
  return (
    <Button
      onPress={() => showCheckInToolModalAction(true)}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}

export default ShowCheckInToolPromptButton;
