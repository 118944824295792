const defaultState = {
  checkedOutToolList: [],
  toolIdInput: "",
  showCheckInToolModal: false,
  showToolCalibrationExpirationWarningModal: false,
  validTools: [],
  showCheckOutToolModal: false,
  toolSearchResults: [],
  toolToCheckOut: {
    id: "",
  },
};

const state = {
  tool: defaultState,
};

export { state as default, defaultState };
