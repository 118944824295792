import { useStoreActions, useStoreState } from "easy-peasy";
import LoginPage from "./LoginPage";
import Page from "./Page";
import { useEffect } from "react";
import { useIsFocused } from "@react-navigation/native";
import BadgePage from "./BadgePage";

function ProtectedPage({ children, ...props }) {
  const { auth, employee } = useStoreState((state) => state);

  const isFocused = useIsFocused();

  const { getAuthUserThunk } = useStoreActions((actions) => actions);

  useEffect(() => {
    isFocused && getAuthUserThunk();
  }, [isFocused]);

  return (
    <Page {...props}>
      {!auth.user.email && <LoginPage />}
      {auth.user.email && !employee.loggedInEmployee.EmployeeID && (
        <BadgePage />
      )}
      {auth.user.email && employee.loggedInEmployee.EmployeeID && children}
    </Page>
  );
}

export default ProtectedPage;
