import { useStoreState } from "easy-peasy";
import Message from "../presentational/Message";
import styled from "styled-components/native";

const Container = styled.View`
  width: 100%;
`;

function Alerts(props) {
  const { alerts } = useStoreState((state) => state.status);
  return (
    <Container {...props}>
      {alerts.map(({ type, ...alert }) => {
        let Component = Message;
        if (type === "error") Component = Message.Error;
        else if (type === "success") Component = Message.Success;
        else if (type === "warning") Component = Message.Warning;
        return <Component key={alert.id}>{alert.message}</Component>;
      })}
    </Container>
  );
}

export default Alerts;
