import { useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { format } from "date-fns";

function CheckedOutToolList() {
  const { checkedOutToolList } = useStoreState((state) => state.tool);
  return (
    <List
      tableHead={["Employee ID", "Task ID", "Tool ID", "Description", "Date"]}
      tableRows={checkedOutToolList.map((tool) => [
        tool.employeeId,
        tool.worktaskId,
        tool.toolId,
        tool.toolDescription,
        format(new Date(tool.checkoutDate), "yyyy-MM-dd"),
      ])}
    />
  );
}

export default CheckedOutToolList;
