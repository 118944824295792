import { useStoreActions, useStoreState } from "easy-peasy";
import List from "../../presentational/List";
import { CheckBox } from "react-native-elements";
import styled from "styled-components/native";
import { vhToDp } from "../../../utilities/responsive";

const Container = styled.ScrollView`
  width: 100%;
  max-height: ${vhToDp(40)}px;
`;

function ToolList() {
  const { toolSearchResults, toolToCheckOut } = useStoreState(
    (state) => state.tool
  );

  const { toggleToolToCheckOutThunk } = useStoreActions((actions) => actions);

  return (
    <Container>
      <List
        tableHead={["Check Out?", "Tool ID", "Description", "Serial Number"]}
        tableRows={toolSearchResults.map((tool) => [
          <CheckBox
            checked={tool.id === toolToCheckOut.id}
            onPress={() => toggleToolToCheckOutThunk(tool)}
          />,
          tool.ToolID.value,
          tool.Description.value,
          tool.SerialNumber.value,
        ])}
      />
    </Container>
  );
}

export default ToolList;
