import { useStoreActions } from "easy-peasy";
import { Entypo } from "@expo/vector-icons";
import Icon from "../../presentational/Icon";
import { remToDp } from "../../../utilities/responsive";

function ShowCheckOutToolPromptButton({ children, ...props }) {
  const { showCheckOutToolModalAction } = useStoreActions((actions) => actions);
  return (
    <Icon
      Component={Entypo}
      name="magnifying-glass"
      onPress={() => showCheckOutToolModalAction(true)}
      size={remToDp(2)}
      {...props}
    />
  );
}

export default ShowCheckOutToolPromptButton;
