import { useStoreState } from "easy-peasy";
import Image from "../../presentational/Image";

function DrawerBrand(props) {
  const { logo } = useStoreState((state) => state.auth.acumaticaConfig);
  return (
    <Image
      source={{
        uri: `https://kioskbranding.blob.core.windows.net/images/${logo}`,
      }}
      {...props}
    />
  );
}

export default DrawerBrand;
