import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function LogoutButton({ children = "Logout", navigation, ...props }) {
  const { logoutEmployeeThunk } = useStoreActions((actions) => actions);
  return (
    <Button
      onPress={() => logoutEmployeeThunk(navigation)}
      fullWidth
      {...props}
    >
      {children}
    </Button>
  );
}

export default LogoutButton;
