import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";

function ClearInputButton({ children = "Clear All Input", ...props }) {
  const { clearEmployeeThunk } = useStoreActions((actions) => actions);
  return (
    <Button fullWidth onPress={() => clearEmployeeThunk()} {...props}>
      {children}
    </Button>
  );
}

export default ClearInputButton;
