import { thunk } from "easy-peasy";
import {
  checkInToolAPICall,
  checkOutToolAPICall,
  searchToolsAPICall,
} from "../../api/tool";
import {
  checkIfToolExpired,
  checkToolCalibrationCurrent,
  checkToolCalibrationExpiration,
  checkToolCheckoutLimit,
} from "../../utilities/tool";

const thunks = {
  checkInToolThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const response = await checkInToolAPICall(
        helpers.getState().tool.toolIdInput
      );
      actions.setToolIdInputAction("");
      actions.showCheckInToolModalAction(false);
      actions.setAlertThunk({
        type: "success",
        message: response.data.successMessage,
      });
      actions.clearEmployeeThunk();
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  fastCheckOutToolThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      checkToolCheckoutLimit(helpers);

      const toolToCheckOut = helpers
        .getState()
        .tool.validTools.find(
          (tool) => tool.ToolID.value === helpers.getState().tool.toolIdInput
        );

      if (!toolToCheckOut) {
        throw new Error(
          "Tool does not exist. If tool is new, click on button to search for it."
        );
      }

      checkIfToolExpired(toolToCheckOut);
      checkToolCalibrationCurrent(toolToCheckOut);

      const response = await checkOutToolAPICall({
        employeeId: helpers.getState().employee.scannedEmployee.EmployeeRefNo,
        worktaskId: helpers.getState().task.scannedTask.TaskCD,
        toolId: toolToCheckOut.ToolID.value,
        kioskuserId: helpers.getState().employee.loggedInEmployee.EmployeeID,
        kioskuserName: helpers.getState().employee.loggedInEmployee.LegalName,
        workorderId: "",
        serialNumber: toolToCheckOut.SerialNumber.value,
        assetTag: "",
        toolDescription: toolToCheckOut.Description.value,
      });

      actions.setCheckedOutToolListAction(response.data.checkedOutToolList);
      actions.setToolIdInputAction("");

      checkToolCalibrationExpiration(
        response.data,
        toolToCheckOut,
        helpers,
        actions
      );
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response ? err.response.data : err.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  slowCheckOutToolThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const { validTools, toolToCheckOut } = helpers.getState().tool;

      checkToolCheckoutLimit(helpers);
      checkToolCalibrationCurrent(toolToCheckOut);
      checkIfToolExpired(toolToCheckOut);

      if (
        !validTools.some(
          (tool) => tool.ToolID.value === toolToCheckOut.ToolID.value
        )
      ) {
        actions.addValidToolsAction(toolToCheckOut);
      }

      const response = await checkOutToolAPICall({
        employeeId: helpers.getState().employee.scannedEmployee.EmployeeRefNo,
        worktaskId: helpers.getState().task.scannedTask.TaskCD,
        toolId: toolToCheckOut.ToolID.value,
        kioskuserId: helpers.getState().employee.loggedInEmployee.EmployeeID,
        kioskuserName: helpers.getState().employee.loggedInEmployee.LegalName,
        workorderId: "",
        serialNumber: toolToCheckOut.SerialNumber.value,
        assetTag: "",
        toolDescription: toolToCheckOut.Description.value,
      });

      actions.setCheckedOutToolListAction(response.data.checkedOutToolList);
      actions.resetToolToCheckOutThunk();
      actions.setToolIdInputAction("");
      actions.showCheckOutToolModalAction(false);

      checkToolCalibrationExpiration(
        response.data,
        toolToCheckOut,
        helpers,
        actions
      );
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response ? err.response.data : err.message,
      });
    }

    actions.setLoadingAction(false);
  }),
  searchToolsThunk: thunk(async (actions, payload, helpers) => {
    actions.setLoadingAction(true);

    try {
      const toolIdInput = helpers.getState().tool.toolIdInput;

      const tool = helpers
        .getState()
        .tool.validTools.find((tool) => tool.ToolID.value === toolIdInput);
      if (tool) {
        actions.setToolSearchResultsAction([tool]);
      } else {
        const checkedOutToolResponse = await searchToolsAPICall(toolIdInput);
        actions.setToolSearchResultsAction(checkedOutToolResponse.data);
      }

      actions.resetToolToCheckOutThunk();
    } catch (err) {
      actions.setAlertThunk({
        type: "error",
        message: err.response.data,
      });
    }

    actions.setLoadingAction(false);
  }),
  resetToolToCheckOutThunk: thunk(async (actions, payload, helpers) => {
    actions.setToolToCheckOutAction({ id: "" });
  }),
  toggleToolToCheckOutThunk: thunk(async (actions, tool, helpers) => {
    if (helpers.getState().tool.toolToCheckOut.id === tool.id)
      actions.resetToolToCheckOutThunk();
    else actions.setToolToCheckOutAction(tool);
  }),
};

export default thunks;
