import { action } from "easy-peasy";

const actions = {
  setTaskIdInputAction: action((state, taskIdInput) => {
    state.task.taskIdInput = taskIdInput;
  }),
  setScannedTaskAction: action((state, task) => {
    state.task.scannedTask = task;
  }),
};

export default actions;
