import { useStoreActions, useStoreState } from "easy-peasy";
import SearchBar from "../../presentational/SearchBar";

function SearchBarTool(props) {
  const { toolIdInput } = useStoreState((state) => state.tool);

  const { searchToolsThunk, setToolIdInputAction } = useStoreActions(
    (actions) => actions
  );

  return (
    <SearchBar
      value={toolIdInput}
      placeholder="Enter tool ID to check out"
      onChangeText={(toolId) => setToolIdInputAction(toolId)}
      onSubmitEditing={() => searchToolsThunk()}
      {...props}
    />
  );
}

export default SearchBarTool;
