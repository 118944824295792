import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import styled from "styled-components/native";

const SButton = styled(Button)`
  margin: 0 10px;
`;

function CloseCheckInToolPromptButton({ children = "Close", ...props }) {
  const { showCheckInToolModalAction } = useStoreActions((actions) => actions);
  return (
    <SButton onPress={() => showCheckInToolModalAction(false)} {...props}>
      {children}
    </SButton>
  );
}

export default CloseCheckInToolPromptButton;
