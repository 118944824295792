import ModalView from "../../presentational/Modal";
import Alerts from "../Alerts";

function Modal({ children, ...props }) {
  return (
    <ModalView {...props}>
      <Alerts />
      {children}
    </ModalView>
  );
}

export default Modal;
