const defaultState = {
  badgeId: "",
  loggedInEmployee: {
    EmployeeID: "",
  },
  scannedEmployee: {
    EmployeeID: "",
  },
};

const state = {
  employee: defaultState,
};

export { state as default, defaultState };
