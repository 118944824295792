import { useStoreActions } from "easy-peasy";
import Button from "../../presentational/Button";
import styled from "styled-components/native";

const SButton = styled(Button)`
  margin: 0 10px;
`;

function CloseToolCalibrationExpirationWarningModalButton({
  children = "I Understand",
  ...props
}) {
  const { showToolCalibrationExpirationWarningModalAction } = useStoreActions(
    (actions) => actions
  );
  return (
    <SButton
      onPress={() => showToolCalibrationExpirationWarningModalAction(false)}
      {...props}
    >
      {children}
    </SButton>
  );
}

export default CloseToolCalibrationExpirationWarningModalButton;
